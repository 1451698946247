import Logo from "./logo.svg";
import Voucher from "./voucher.png";
import VoucherGreen from "./voucher-green.png";
import MockQR from "./mock-qr.png";
import Wallet from "./wallet.png";
import WalletSmall from "./wallet-small.png";
import Money from "./money.png";
import Cart from "./cart.png";
import EmptyCart from "./empty-cart.png";
import Help from "./help.png";
import DefaultAvatar from "./default-avatar.png";
import Gift from "./gift.png";
import Logout from "./logout.png";
import HeaderImg from "./header-img.jpg";
import HeaderBlur from "./hero-blur.png";
import Seeding from "./seeding.png";
import Accounts from "./accounts.png";
import Sale from "./sale.png";
import BoxEmpty from "./box-empty.png";
import HearthFooter from "./heart-footer.png";
import CoffeeFooter from "./coffee-footer.png";
import DefaultThumbnail from "./default_image.png";
import Heart from "./heart.png";

import CoreValue1 from "./core-value-1.png";
import CoreValue2 from "./core-value-2.png";
import CoreValue3 from "./core-value-3.png";
import CoreValue4 from "./core-value-4.png";
import CoreValue5 from "./core-value-5.png";

export {
  Logo,
  Voucher,
  MockQR,
  Wallet,
  Money,
  Cart,
  WalletSmall,
  Help,
  VoucherGreen,
  DefaultAvatar,
  Gift,
  EmptyCart,
  Logout,
  HeaderImg,
  HeaderBlur,
  CoreValue1,
  CoreValue2,
  CoreValue3,
  CoreValue4,
  CoreValue5,
  Seeding,
  Accounts,
  Sale,
  BoxEmpty,
  HearthFooter,
  CoffeeFooter,
  DefaultThumbnail,
  Heart,
};
